import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout'
import Button from '../components/button'
import SEO from '../components/seo'
import ProjectPostItem from '../components/project-post-item'
import { Link } from 'gatsby'

// import { useValueTrackLogic } from '../hooks/useValueTrack'
import { getValueTrackParams as getValueTrackingParams } from '../helpers/adRefTracking'

import Breadcrumbs from '../components/breadcrumbs'
import { useLocalStorage } from '../hooks/useLocalStorage'

const BlogPostsLayoutContainer = styled.div`
  display: flex;
  padding: 2em 1em;
  flex-wrap: wrap;
  align-items: stretch;
`

const Heading = styled.div`
  padding: 3em 1em;
  padding-bottom: 5em;
  text-align: center;
  margin-bottom: -5em;
  h1 {
    font-size: calc(1.15vw + 1.25em);
    margin-bottom: 0.15em;
  }
  p {
    color: #666;
    margin-bottom: 0.15em;
  }
`

const ProjectsPage = (props) => {
  const data = useStaticQuery(graphql`
    query ProjectsPage {
      allContentfulTestimonialCategory {
        edges {
          node {
            id
            name
            slug
          }
        }
      }
      allContentfulProjects(sort: { order: DESC, fields: date }) {
        edges {
          node {
            id
            slug
            title
            category {
              id
              name
              slug
            }
            date
            city
            zipCode
            thumbnail {
              gatsbyImageData(
                width: 585
                height: 390
                quality: 70
                placeholder: NONE
              )
            }
          }
        }
      }
    }
  `)

  const { campaigns } = getValueTrackingParams()

  // const hiddenVTRef = useValueTrackLogic({ isCampaign: 'siding' })

  const [hasCategoryViewRestriction, setHasCategoryViewRestriction] = useState(false)
  const [hasCategoryViewRestrictionProjects, setHasCategoryViewRestrictionProjects] = useState([])

  const [selectedFiltersStorage, setSelectedFiltersStorage] = useLocalStorage('selectedProjects', '')
  const [selectedFilters, updateSelectedFilters] = useState(selectedFiltersStorage ? selectedFiltersStorage : [])

  const onFilterSelect = (e) => {
    if (e.target.dataset.filterId == '1-all-items') {
      updateSelectedFilters([])
      //set the selection to localstorage
      setSelectedFiltersStorage([])
      return
    }
    if (!selectedFilters.includes(e.target.dataset.filterId)) {
      updateSelectedFilters([...selectedFilters, e.target.dataset.filterId])

      //set the selection to localstorage
      setSelectedFiltersStorage([...selectedFilters, e.target.dataset.filterId])
    } else {
      updateSelectedFilters(
        selectedFilters.filter((item) => item !== e.target.dataset.filterId)
      )
      //set the selection to localstorage
      setSelectedFiltersStorage(selectedFilters.filter((item) => item !== e.target.dataset.filterId))
    }
  }

  //check to see if limiting categories to show
  useEffect(() => {
    if (props.location && props.location.search && props.location.search.includes('?categories') || (campaigns && campaigns.length > 0 && (campaigns == 'siding' || campaigns == 'remodels'))) {
      let categoriesToShow = []

      //if a campaign is set, restrict viewable categories
      if (campaigns && campaigns.length > 0) {
        if (campaigns == 'siding') {
          categoriesToShow = ['siding', 'windows']
        } else if (campaigns == 'remodels') {
          categoriesToShow = ['kitchen', 'bathroom']
        }
      }

      //url params can override viewable categories
      if (props.location.search.includes('?categories')) {
        categoriesToShow = [...(props.location.search.split('?categories='))[1].split(',')]
      }

      setHasCategoryViewRestriction(data.allContentfulTestimonialCategory.edges.filter(cat => categoriesToShow.includes(cat.node.projectCategoryAlias.toLowerCase())))

      let viewableProjects = []
      data.allContentfulProjects.edges.forEach(project => {
        project.node.category.forEach(category => {
          if (categoriesToShow.includes(category.projectCategoryAlias.toLowerCase())) {
            viewableProjects.push(project)
          }
        })

      });

      setHasCategoryViewRestrictionProjects([...viewableProjects])
    }
  }, [])

  return (
    <Layout pageProps={props}>
      <div className="border-b border-gray-100">
        <nav
          className="flex container mx-auto px-4 py-3"
          aria-label="Breadcrumb"
        >
          <Breadcrumbs
            links={[
              { to: '/', label: 'Home' },
              { to: props.location.pathname, label: 'Projects' },
            ]}
          />
        </nav>
      </div>
      <SEO
        title={'Recently Completed Projects - US Quality Construction of Columbus'}
        description={
          'Explore our collection of recently completed projects for home renovation inspiration and ideas.'
        }
      />
      <Heading style={{ paddingTop: '1em' }}>
        <h1>Recently Completed Projects</h1>
        <p>
        Take a look at some of the home transformations we've recently completed and see how we can transform your own space.
        </p>
        <div
          className="container mx-auto text-center py-2 px-0"
          // ref={hiddenVTRef}
        >
          {[
            {
              node: {
                id: '1-all-items',
                name: 'All Projects',
                slug: '',
              },
            },
            ...data.allContentfulTestimonialCategory.edges,
          ].map(({ node: { id, name, projectCategoryAlias, slug } }) => (
            <div
              onClick={onFilterSelect}
              className={`relbtn text-sm ${
                selectedFilters.includes(id) ||
                (id == '1-all-items' && selectedFilters.length == 0)
                  ? 'active'
                  : ''
              }`}
              data-filter-id={id}
            >
              {projectCategoryAlias ? projectCategoryAlias : name}
            </div>
          ))}
        </div>
      </Heading>
      <BlogPostsLayoutContainer className="container">
        {data.allContentfulProjects.edges
          .filter((item) => {
            return selectedFilters.length > 0
              ? item.node.category.filter(({ id }) =>
                  selectedFilters.includes(id)
                ).length > 0
              : true
          })
          .map((item, i) => (
            <ProjectPostItem
              key={i}
              title={item.node.title}
              slug={item.node.slug}
              image={item.node.thumbnail}
              city={item.node.city}
              zipCode={item.node.zipCode}
              date={item.node.date}
              publishDate={item.node.publishDate}
              category={item.node.category.name}
              categorySlug={item.node.category.slug}
              author={null}
              page={'projects'}
              excerpt={item.node.metaDescription}
            />
          ))}
      </BlogPostsLayoutContainer>
    </Layout>
  )
}

export default ProjectsPage
